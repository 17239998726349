/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { Link } from 'gatsby';

// Prototypes
import PropTypes from 'prop-types';

// UI lib components
import { Container } from 'react-grid-system';

// Assets
import TITLE_ICON from '../../../images/index/icons/title-icon.svg';
import NEXT_ICON from '../../../images/index/icons/next-Arrow.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Title Section                                */
/* -------------------------------------------------------------------------- */

function TitleSection({ pageLink, title, titleLink }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="seperator-container" fluid>
      <div className="title-section flex justify-between fluid">
        <Container className="title-section__content flex items-center mr-0 ml-0 fluid">
          <img className="title_icon" src={TITLE_ICON} alt="title_icon" />
          <h2>{title || 'default title'}</h2>
        </Container>

        {titleLink && (
          <Link
            className="title-section__content flex items-center mr-0 ml-0 fluid"
            to={pageLink}
          >
            <Container
              className="animated-title flex items-center mr-0 ml-0 fluid"
              fluid
            >
              <h3>{titleLink || 'default title link'}</h3>
              <img className="next_icon" src={NEXT_ICON} alt="next_icon" />
            </Container>
          </Link>
        )}
      </div>
    </Container>
  );
}

TitleSection.propTypes = {
  pageLink: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
};

TitleSection.defaultProps = {
  titleLink: '',
  pageLink: '',
};

export default TitleSection;
