/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';
import ContactSection from '../page-components/page-index/contact';

// Assests
import BANNER_IMG from '../images/index/banner-section/banner.jpg';

// Styles
import '../page-styles/contact.scss';

/* -------------------------------------------------------------------------- */
/*                               Contact Page                                 */
/* -------------------------------------------------------------------------- */

function ContactPage() {
  /* ---------------------------------- CONSTANSTS --------------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Contact';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <BannerSection bannerImage={BANNER_IMG}>
        <h1>CONTACT</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs path="contact" titleBreadcrumbs="Contact" />
        </Container>
      </Visible>
      <Container className="contact-page top fluid" fluid>
        <ContactSection />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactPage;
