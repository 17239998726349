/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
// Prototypes
import PropTypes from 'prop-types';

// UI lib components
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               BreadCrumbs                                  */
/* -------------------------------------------------------------------------- */

function BeardCrumbs({ path, titleBreadcrumbs, isDetailed }) {
  /* ******************************** RENDERING ******************************* */
  // LOCALIZATION
  const { t } = useTranslation();
  const { titleBreadcrumbs1, titleBreadcrumbsDetails } = t('detailsReference', {
    returnObjects: true,
  });
  return (
    <Container className="breadCrumbs-container" fluid>
      <Link className="breadcrumb" to="/">
        {titleBreadcrumbs1}
      </Link>
      <Link className="breadcrumb" to={`/${path}`}>
        {titleBreadcrumbs}
      </Link>
      {isDetailed && (
        <Link className="breadcrumb" to="/details-reference">
          {titleBreadcrumbsDetails}
        </Link>
      )}
    </Container>
  );
}

BeardCrumbs.propTypes = {
  isDetailed: PropTypes.string,
  path: PropTypes.string.isRequired,
  titleBreadcrumbs: PropTypes.string.isRequired,
};
BeardCrumbs.defaultProps = {
  isDetailed: '',
};

export default BeardCrumbs;
