/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import TitleSection from '../titleSection';

// Assets
import CONTACT_IMG from '../../../images/index/contact-section/contact.webp';
import EMAIL_ICON from '../../../images/icons/email.svg';
import PHONE_ICON from '../../../images/icons/phone.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Contact Section                                */
/* -------------------------------------------------------------------------- */

function ContactSection() {
  const { t } = useTranslation();
  const { title, tel, email, inputs, textarea, btn } = t('Contact', {
    returnObjects: true,
  });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */
  const Inputs = inputs?.map(
    ({ id, classN, name, typeInput, placeholderInput }) => (
      <input
        key={id}
        className={classN}
        type={typeInput}
        name={name}
        placeholder={placeholderInput}
        required
      />
    ),
  );
  /* ******************************** RENDERING ***************************** */
  return (
    <Container id="contact" className="contact-section flex fluid" fluid>
      <Container className="contact-section__form fluid" fluid>
        <TitleSection title={title} />
        <Container
          className="single-element flex items-center"
          onClick={() => window.open('tel:+21671752300')}
        >
          <img className="icon" src={PHONE_ICON} alt="phone-icon" />
          <p className="text">{tel}</p>
        </Container>
        <Container className="group-form">
          <form action="https://formspree.io/f/xoqrvezq" method="post">
            {Inputs}
            <textarea
              className="form-control"
              name="message"
              placeholder={textarea}
            />
            <button type="submit">{btn}</button>
          </form>
        </Container>
      </Container>
      <Container className="contact_img fluid" fluid>
        <img src={CONTACT_IMG} alt="contact" />
        <Container className="contact-details flex items-center">
          <Container
            className="single-element flex items-center fluid"
            onClick={() => window.open('tel:+21671752300')}
          >
            <img className="icon" src={PHONE_ICON} alt="phone-icon" />
            <p className="text">{tel}</p>
          </Container>
          <Container
            className="single-element flex items-center fluid"
            onClick={() => window.location.assign('mailto:ltt@luxor.com.tn')}
          >
            <img className="icon" src={EMAIL_ICON} alt="search-icon" />
            <p className="text">{email}</p>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default ContactSection;
