/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Banner Section                               */
/* -------------------------------------------------------------------------- */

function BannerSection({ children, isSolutionPage, bannerImage }) {
  /* ********************************* CONST ********************************** */
  // eslint-disable-next-line max-len
  const INLINE_BANNER_BG = `linear-gradient(to bottom, rgba(39, 39, 39, 0.52), rgba(12, 12, 12, 0.73)), url(${bannerImage})`;
  /* ******************************** RENDERING ******************************* */
  return (
    <Container
      className={`banner-section ${isSolutionPage && 'simple-banner-bg'} fluid`}
      style={{ backgroundImage: !isSolutionPage && INLINE_BANNER_BG }}
      fluid
    >
      <Container className="banner-section__content flex items-center fluid">
        {children}
      </Container>
    </Container>
  );
}

BannerSection.propTypes = {
  children: PropTypes.string.isRequired,
  isSolutionPage: PropTypes.bool,
  bannerImage: PropTypes.string,
};

BannerSection.defaultProps = {
  isSolutionPage: false,
  bannerImage: '',
};

export default BannerSection;
